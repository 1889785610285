import React from "react";
import styled from "styled-components";

const ProjectInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (min-width: 700px) {
    flex-direction: row-reverse;
  }

  &:nth-of-type(2n) {
    flex-direction: row;
  }
`;

const ProjectWrapper = styled.div`
  padding-bottom: 90px;

  @media (min-width: 700px) {
    &:nth-of-type(2n) ${ProjectInnerWrapper} {
      flex-direction: row;
    }
  }
`;

const ProjectTitle = styled.h2`
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.1;

  @media (min-width: 700px) {
    font-size: 48px;
    margin-bottom: 10px;
  }
`;

const ProjectSubtitle = styled.h3`
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: rgb(55, 55, 55);
  @media (min-width: 700px) {
    font-size: 32px;
  }
`;

const ProjectContentContainer = styled.div`
  flex-basis: 75%;
  font-size: 18px;
  line-height: 24px;

  & > p {
    margin-top: 18px;
    margin-bottom: 18px;
    letter-spacing: 1px;
  }

  & code {
    font-family: monospace;
    font-size: 14px;
    color: #fec343;
  }

  & strong {
    color: #fc136e;
    font-weight: inherit;
  }

  @media (min-width: 700px) {
    flex-basis: 52%;
    font-size: 22px;
    line-height: 29px;
  }
`;

const ProjectImageContainer = styled.div`
  padding-top: 20px;

  & > img {
    display: block;
    width: 100%;
    padding-right: 20px;
  }

  @media (min-width: 700px) {
    flex-basis: 48%;
    padding-left: 0;
    padding-right: 20px;
  }
`;

const Project = ({ content, images, title, subtitle }) => {
  return (
    <ProjectWrapper>
      <ProjectInnerWrapper>
        <ProjectContentContainer>
          <ProjectTitle>{title}</ProjectTitle>
          <ProjectSubtitle>{subtitle}</ProjectSubtitle>
          <ProjectContentContainer>
            {content.map((element, i) =>
              React.cloneElement(element, {
                key: title + "-content-" + i.toString()
              })
            )}
          </ProjectContentContainer>
        </ProjectContentContainer>
        <ProjectImageContainer>
          {React.cloneElement(images[0], { className: "project--image" })}
        </ProjectImageContainer>
      </ProjectInnerWrapper>
    </ProjectWrapper>
  );
};

export default Project;
