import React from "react";
import styled from "styled-components";
import { Heading, Section } from "./index"; 

const LinkList = styled.ul`
  display: flex;
  margin-left: 0;
    padding-left: 0;
  
  @media (max-width: 419.999px) {
    flex-direction: column;
    
  }
  
  `;

const LinkListItem = styled.li`
  display: block;
  // font-size: 24px;

  a {
    color: rgb(26, 26, 26);
    font-size: 18px;
    text-decoration: none;

    @media (min-width: 420px) {
      font-size: 24px;
    }
  }

  a:hover {
    border - bottom: 1px solid #FEC343;
  }

  &:nth-of-type(2) a:hover {
    border-bottom: 1px solid #FC136E;
  }

  &:nth-of-type(3) a:hover {
    border-bottom: 1px solid #38CFFC;
  }

  &:nth-of-type(3) a:hover {
    border-bottom: 1px solid #BFD934;
  }

  &:nth-of-type(3) a:hover {
    border-bottom: 1px solid #33D366;
  }

  &:not(:last-child) {
    padding-right: 20px;
  }
`;

const Contact = props => {
  return (
    <Section>
      <Heading>{props.title || "Let's talk"}</Heading>
      <LinkList>
        <LinkListItem><a href="https://twitter.com/thefivetoes">twitter</a></LinkListItem>
        <LinkListItem><a href="https://github.com/thefivetoes">github</a></LinkListItem>
        <LinkListItem><a href="https://linkedin.com/in/thefivetoes">linkedin</a></LinkListItem>
        <LinkListItem><a href="mailto:justin@jws.io">justin@jws.io</a></LinkListItem>
      </LinkList>
    </Section>
  );
};

export default Contact;

