import styled from "styled-components";

export const Heading = styled.h1`
    font-family: "Nunito", sans-serif;
    font-weight: 700;
    font-size: 96px;
    color: rgb(26, 26, 26);
    margin-left: -7px;
    line-height: 1.1;
`;

export const Section = styled.section`
  padding: 100px 0;
  background: #fff;
`;
