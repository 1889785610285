// import React from "react";

// import Footer from "../components/Footer";
// import Etc from "../components/Projects/Etc";
// import GW from "../components/Projects/GW";
// import Wisr from "../components/Projects/Wisr";
// import Header from "../components/Header";
// import Link from "gatsby-link";
// import Projects from "../components/Projects";
// import styled from "styled-components";
// /*
// yellow:     #f2ff49
// red:        #ff4242
// purple:     #fb62f6
// blue:       #645dd7
// light blue  #b3fffc
// */

// const LARGE_BREAKPOINT = "700px";

// const HR = styled.hr `
//   display: block;
//   height: 1px;
//   border: none;
//   outline: none;
//   width: 100%;
//   background: #ddd;
//   margin: 2rem 0;
// `;

// const Wrapper = styled.div`
//   font-family: "Montserrat", sans-serif;
//   font-weight: 200;
//   color: #333;
// `;

// const IndexPage = ({data}) => (
//   <Wrapper>
//     <Header />
//     <HR />
//     <Wisr/>
//     <HR />
//     <GW/>
//     <HR />
//     <Etc/>
//     <HR />
//     <Footer />
//   </Wrapper>
// );

// export default IndexPage;

/* global require */
import React from "react";
// import { Link } from "react-router";
import Intro from "../components/Intro.jsx";
import Contact from "../components/Contact.jsx";
import Project from "../components/Project.jsx";
import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 0 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    
    @media(max-width: 419.9999px) {
      padding: 0 5px;
    }
  `;

export default class Index extends React.Component {
  render() {
    return (
      <Wrapper>
        <Intro title="Oh, hello." />
        <Project
          title="Rise"
          subtitle="React + web3.js"
          content={[
            <p>
              Rise is an international talent management platform, a project 
              management tool, and a payment management solution for creative 
              teams. 
            </p>,
            <p>
              Freelancers&apos; work was managed via a custom Ethereum Smart Contract.
              Through the React app, users could interact with the Smart Contract
              to define the scope of work, agree upon milestones and acheivements, 
              and exchange funds. Freelancers could choose to be paid in Crypto
              stablecoins, or in fiat. 
            </p>
          ]}
          images={[<img src={require("../images/rise.png")} />]}
        />
        <Project
          title="Wisr"
          subtitle="Python Django + React."
          content={[
            <p>
              Wisr is a platform for connecting students at colleges and
              universities with alums from that university for mentoring and
              career advising. We created a private network for each institution,
              which in-turn helps to bolster student success and alumni
              engagement.
            </p>,
            <p>
              Each Wisr site is highly customized to based on the needs of the
              students at each school. We were a small team at a scrappy startup, working in an
              industry that evolves slowly and expected enterprise level
              configurability. As the
              lead UX/UI engineer on the team, I touched every piece of our product,
              from product design and planning, to designing and building
              features.
            </p>
          ]}
          images={[<img src={require("../images/wisr-welcome-light.png")} />]}
        />
        <Project
          title="The Groundwork Admin"
          subtitle="Python Django + React."
          content={[
            <p>
              The Groundwork was founded to create a tech platform for Hillary
              Clinton’s 2016 presidential campaign. That same technology was
              spun off as a Platform-as-a-Service product which provides those
              same tools to non-profits and NGOs. The Admin app provided
              customers with a place to configure the platform, manage donation,
              supporter and event data, and view interactive reporting.
            </p>,
            <p>
              The Groundwork Admin was a single page React/Redux app, built on
              top of a Django Rest Framework backend which served as a
              passthrough to a microservice architecture. We used Babel to
              translate ES2015/16 to web-safe JavaScript.
            </p>
          ]}
          images={[<img src={require("../images/gw-admin-01.png")} />]}
        />
        <Project
          title="Hyr Medical"
          subtitle="Rails + React."
          content={[
            <p>
              Hyr Medical is a Cleveland-based startup building a platform that
              connects doctors and hospitals for part time work. Hyr"s two-sided
              marketplace allows hospitals to post job openings, and physicians
              to view and apply for jobs.{" "}
            </p>,
            <p>
              As the CTO of Hyr Medical, I worked
              closely with a team of designers and developers to
              architect, design, and build the MVP. I was also involved in
              fundraising and attended several investor pitches, while working
              closely with the CEO and CMO to refine the business strategy.
            </p>
          ]}
          images={[<img src={require("../images/hyrmed-01.png")} />]}
        />
        <Project
          title="Supporter Signup Block"
          subtitle="Front End Dev, UX Design."
          content={[
            <p>
              The Groundwork created a series of tools to allow new
              organizations to quickly spin up and deploy customized "blocks"
              for data capture. A Donation Block allowed customers to configure
              and embed a donation form on their site in minutes. Additionally,
              we built a Supporters Signup Block, which accepted a wide variety
              of customizable user data. We also created a Builder utility for
              each Block, which provided customers with a UI for configuring and
              customizing their Blocks prior to embedding them into their
              websites.{" "}
            </p>,
            <p>
              The Supporter Signup Block was built on top of a custom JavaScript
              framework, which utilized jsx templating, and vanilla JavaScript
              for configuration logic. Each Block was embedded via a single
              script element, with custom data attributes for configuration.
            </p>
          ]}
          images={[<img src={require("../images/gw-supporters-01.png")} />]}
        />
        {/* <Project
          title="aParently"
          subtitle="Rails App."
          content={[
            <p>
              aParently is a tool for parents of children in a shared group
              (i.e. a classroom, a dance class) to connect and collaborate. With
              aParently, they can easily coordinate car pools, playdates, or
              share important news.
            </p>,
            <p>
              This MVP was built as a mobile-first web app, using Rails and
              deployed to Heroku. I worked remotely, with a very small team of
              part-time engineers and a designer. The final product was
              beta-tested in a number of classrooms at private schools in New
              York City.
            </p>
          ]}
          images={[<img src={require("../images/aparently-04.png")} />]}
        />
        <Project
          title="TruTV Research"
          subtitle="Research, Digital Strategy."
          content={[
            <p>
              In 2015, TruTV was rebranded, and began airing new comedy and
              reality-based shows. As part of this rebranding, all of their
              digital assets were reviewed and updated to capture and engage a
              new audience.
            </p>,
            <p>
              Working with a longtime agency partner, I led the digital strategy
              portion of the rebrand. We conducted analysis of existing usage of
              digital properties, competitive analysis, and developed a guide
              for best practices. We provided direction and tooling for creating
              a consistent brand experience across a wide variety of media
              including video streaming platforms, social media, websites,
              microsites, and mobile apps.
            </p>
          ]}
          images={[<img src={require("../images/tru-01.png")} />]}
        />
        <Project
          title="HPE Marketing Site"
          subtitle="Information Architecture, UX Design."
          content={[
            <p>
              HP rebranded the enterprise portion of their business as HPE in
              2015. We developed a website to introduce and detail out the new
              video guidelines to marketing partners.{" "}
            </p>,
            <p>
              In partnership with a New York-based video production agency, I
              developed the information architecture and a series of wireframes
              for the brand guidelines website.
            </p>
          ]}
          images={[<img src={require("../images/hpe-01.png")} />]}
        /> */}
        <Contact />
      </Wrapper>
    );
  }
}
