import React from "react";
import styled from "styled-components";
import { Heading, Section } from "./index"; 

const SubHeading = styled.span`
  display: block;
  font-size: 36px;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  max-width: 18em;

  &:nth-of-type(1) {
    color: #33d366;
    display: inline;
  }

  &:nth-of-type(2) {
    display: inline;
  }
  
  @media(max-width: 419.999px) {
    font-size: 28px;
  }
`;


const Contact = props => {
  return (
    <Section>
      <Heading>{props.title}</Heading>
      <SubHeading>My name is Justin Smith.</SubHeading><br/>
      <SubHeading>I make websites.</SubHeading><br/>
      <SubHeading>This is what I’ve been working on.</SubHeading>
    </Section>
  );
};

export default Contact;


